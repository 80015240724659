import { render, staticRenderFns } from "./AuditLogs.vue?vue&type=template&id=466c7d2a&scoped=true&"
import script from "./AuditLogs.vue?vue&type=script&lang=js&"
export * from "./AuditLogs.vue?vue&type=script&lang=js&"
import style0 from "./AuditLogs.vue?vue&type=style&index=0&id=466c7d2a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466c7d2a",
  null
  
)

export default component.exports